body{
  margin:0;
  padding:0;
  font: 18px/1.2em Roboto;
  background-color: #bac2c9
}




.Navlink{
  text-decoration: none;
}

.Navlink.active{
  background-color: #1668b9;
}
.Navlink2{
  text-decoration: none;
  color:black;
}

.Navlink2.active li{
  background-color: #9acafa !important;
}


.MuiTypography-code {
  font-family: "Courier New", monospace;
  background-color: #f0f0f0;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}



.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.a-chname{
  display: block;
  font: 11px/1.1em Tahoma;
  text-decoration: none;
}

.a-chname:hover{
  text-decoration: underline;
}

.pro td{
  font: 12px/1.2em Tahoma;
}

.epg-src-status{
  cursor: pointer;
}


.nopadding-td td{
  padding:3px 10px !important;
}

.ds{
  color:#dbd8d8;
}

.loader-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    opacity: 0;
    animation: fadeIn .5s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Начальная непрозрачность при старте анимации */
  }
  to {
    opacity: 1; /* Конечная непрозрачность после завершения анимации */
  }
}






.bg-grad {
  background: linear-gradient(-45deg, #FF5733, #FFC300, #33FF57, #33D0FF);
  /* background-size: 400% 400%;
  animation: gradient 30s ease infinite; */
}
/* 
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */